import React, { useState } from 'react';
import './Navbar.css';
import { withTranslation } from 'react-i18next';
import { NavLink, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, Fade, SwipeableDrawer, Grid } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';

import Popper from '@material-ui/core/Popper';
import logo from '../../images/hpbLogo.png'
import i18n from '../../i18n';
import moreIcon from '../../images/moreIcon.png'
// import smallOtc from '../../images/smallOtc.png'

import location from '../../images/location.png'
import email from '../../images/email.png'
import phone from '../../images/phone.png'
import whiteLogo from '../../images/hpbLogo.png'

import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import smallOtc from '../../images/smallOtc.png'

import { isMobile } from 'react-device-detect';

function Navbar({ height, width, t, sendBackHeight }) {

    const useStyles = makeStyles((theme) => ({
        navBut: { color: 'red', fontWeight: 'bold', textTransform: 'none', marginLeft: 40, marginRight: 40 },
        drawerBut: { color: 'white', fontWeight: 'bold', marginTop: 4, marginLeft: 40, marginRight: 40, marginBottom: 4, backgroundColor: '#162E83', textTransform: 'none', fontSize: 10 }

    })
    );
    const classes = useStyles();
    const history = useHistory();
    const [openMore, setOpenMore] = React.useState(false);

    const anchorRef = React.useRef(null);
    const [currentPath, setCurrentPath] = useState('')


    //handle popup boxes
    const handleToggle = () => {

        setOpenMore((openMore) => !openMore);
    };



    const changeLanguage = (e) => {
        let newLang = i18n.language == 'en' ? 'cn' : 'en'
        i18n.changeLanguage(newLang);
        localStorage.setItem('lng', newLang)
    }
    const navLinks = [


    ]







    const openLayer2 = () => {
        const newWindow = window.open('https://swapalltrade.com', '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const handleOpenLink = (to) => {
        // popupState.close
        window.open(to);

    };


    return (
        <div style={{ flexDirection: 'column', justifyContent: 'space-between', height: 100, alignItems: 'center',backgroundColor:'#0A091B'}}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', height: 100, minWidth: 320 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '91%' }}>
                    {width > 900 ?
                        <Button onClick={() => { history.replace('/') }} aria-label="home" style={{ height: '100%' }}>
                            <img className="logoButton" src={logo} />
                        </Button>
                        :
                        null
                    }
                    <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '80%', alignSelf: 'center', alignItems: 'center', justifyContent: width > 900 ? 'center' : "flex-start" }}>
                        {
                            width > 900 ?
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>



                                    <PopupState variant="popper" popupId="demo-popup-menu">
                                        {(popupState) => (
                                            <React.Fragment>

                                                <h4 className="textfont" style={{ marginLeft: 32, marginRight: 32, fontSize: 16, color: 'white' }} {...bindHover(popupState)}>{t('home')}</h4>
                                                <Popper {...bindPopper(popupState)} transition>
                                                    {({ TransitionProps }) => (
                                                        <Fade {...TransitionProps} timeout={400}>
                                                            <Paper style={{ backgroundColor: '#101B66', flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}>
                                                                {/* <Button
                                                                    // onClick={() => changeLanguage()}
                                                                    variant="text"
                                                                >

                                                                    <h4 className="textfont" style={{ marginLeft: 24, marginRight: 24, fontSize: 16, color: '#283A96' }}>{t('contact')}</h4>


                                                                </Button> */}


                                                            </Paper>
                                                        </Fade>
                                                    )}
                                                </Popper>
                                            </React.Fragment>
                                        )}
                                    </PopupState>



                                    <PopupState variant="popper" popupId="demo-popup-menu">
                                        {(popupState) => (
                                            <React.Fragment>

                                                <h4 className="textfont" style={{ marginLeft: 32, marginRight: 32, fontSize: 16, color: 'white' }} {...bindHover(popupState)}>{t('aboutUs2')}</h4>
                                                <Popper {...bindPopper(popupState)} transition>
                                                    {({ TransitionProps }) => (
                                                        <Fade {...TransitionProps} timeout={400}>
                                                            <Paper style={{ backgroundColor: '#101B66', flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}>
                                                                <Paper style={{ backgroundColor: '#101B66', flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', width: 300, alignItems: 'start', justifyContent: 'center' }}>

                                                                        <img style={{ height: 51*0.7, width: 137*0.7 ,marginLeft:8,marginTop:12,marginBottom:20}} src={whiteLogo} />

                                                                        <h4 className="textfont" style={{ marginLeft: 16, marginRight: 16,  fontSize: 16, color: 'white' }}>HPB Link empower developers and data analyst with a wide range of productive experiences for building, training and deploying machine learning models faster. Accelerate time to market and foster team collaboration with industry-leading MLOps – DevOps for machine learning. Innovate on a secure, trusted platform, designed for responsible AI.</h4>


                                                                    </div>


                                                                </Paper>

                                                            </Paper>
                                                        </Fade>
                                                    )}
                                                </Popper>
                                            </React.Fragment>
                                        )}
                                    </PopupState>




                                    <PopupState variant="popper" popupId="demo-popup-menu">
                                        {(popupState) => (
                                            <React.Fragment>

                                                <h4 className="textfont" style={{ marginLeft: 32, marginRight: 32, fontSize: 16, color: 'white' }} {...bindHover(popupState)}>{t('contact')}</h4>
                                                <Popper {...bindPopper(popupState)} transition>
                                                    {({ TransitionProps }) => (
                                                        <Fade {...TransitionProps} timeout={400}>
                                                            <Paper style={{ backgroundColor: '#101B66', flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'column', width: 400, height: 240, alignItems: 'start', justifyContent: 'center', paddingLeft: 24 }}>

                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                        <img style={{ height: 24, width: 24 }} src={location} />
                                                                        <h4 className="textfont" style={{ marginLeft: 8, fontSize: 12, color: 'white' }}> 5255 Yonge St, ON CA</h4>
                                                                    </div>


                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                        <img style={{ height: 24, width: 24 }} src={email} />
                                                                        <h4 className="textfont" style={{ marginLeft: 8, fontSize: 12, color: 'white' }}>contact@hpblink.ca</h4>
                                                                    </div>

                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                        <img style={{ height: 24, width: 24 }} src={phone} />
                                                                        <h4 className="textfont" style={{ marginLeft: 8, fontSize: 12, color: 'white' }}>+1 837 928 3746</h4>
                                                                    </div>

                                                                    {/* <Button style={{height:48}} onClick={() => { handleOpenLink("https://otc.yesbit.io/") }} className={classes.navBut}>
                                                                        <img style={{ height: 18, width: 18, marginRight: 4 }} src={smallOtc} />
                                                                        <h4 className="textfont" style={{ marginLeft: 8, fontSize: 12, color: 'white' }}>{t('to')} Yesbit OTC</h4>
                                                                    </Button> */}
                                                                </div>


                                                            </Paper>
                                                        </Fade>
                                                    )}
                                                </Popper>
                                            </React.Fragment>
                                        )}
                                    </PopupState>

                                    {/* <Button style={{ marginLeft: 16 }}
                                            onClick={() => {window.open("https://otc.yesbit.io/")}}
                                            variant="text">

                                        <h4 className="textfont" style={{ marginLeft: 24, marginRight: 24, fontSize: 16, color: '#283A96' }}>   {t('yesbitOTC')}</h4>


                                    </Button>

                                    <Button style={{ marginLeft: 16 }}
                                        onClick={() => changeLanguage()}
                                        variant="text">

                                        <h4 className="textfont" style={{ marginLeft: 24, marginRight: 24, fontSize: 16, color: '#283A96' }}>   {t('lang')}</h4>


                                    </Button> */}


                                </div>


                                :
                                <button className='button__lang' style={{ backgroundColor: 'transparent', height: '80%', marginLeft: 0 }}
                                    ref={anchorRef}
                                    aria-controls={openMore ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                >

                                    <img style={{ height: 24, width: 24 }} src={moreIcon} />
                                </button>


                        }
                    </div>
                    <div style={{ justifyContent: 'center', marginLeft: 'auto' }}>






                        <SwipeableDrawer
                            anchor={"left"}
                            open={openMore}
                            onClose={() => setOpenMore(false)}
                            onOpen={() => setOpenMore(true)}
                        >
                            <div style={{ backgroundColor: '#091B78', width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>

                                <Button className={classes.drawerBut} > <h4 className="textfont" style={{ marginLeft: 24, marginRight: 24, fontSize: 16, color: 'white' }}>{t('home')}</h4></Button>

                                <Button className={classes.drawerBut} > <h4 className="textfont" style={{ marginLeft: 24, marginRight: 24, fontSize: 16, color: 'white' }}>{t('aboutUs2')}</h4></Button>
                                <Button className={classes.drawerBut} > <h4 className="textfont" style={{ marginLeft: 24, marginRight: 24, fontSize: 16, color: 'white' }}>{t('contact')}</h4></Button>
                                <Button onClick={() => {window.open("https://otc.yesbit.io/")}} className={classes.drawerBut} > <h4 className="textfont" style={{ marginLeft: 24, marginRight: 24, fontSize: 16, color: 'white' }}>{t('yesbitOTC')}</h4></Button>
                                <Button onClick={() => changeLanguage()} className={classes.drawerBut} > <h4 className="textfont" style={{ marginLeft: 24, marginRight: 24, fontSize: 16, color: 'white' }}>{t('lang')}</h4></Button>

                            </div>
                        </SwipeableDrawer>



                    </div>
                </div>
            </div >
        </div >
    )
}

export default withTranslation()(Navbar);
