import React from 'react';
import {Grid} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import './Home.css';
// import { FormatNumber } from "../../utils/FormatNumber";
import headImg from '../../images/headerImage.png'

const largeBreakPoint = 1400
const midBreakPoint = 960
const table1 = 1665
const table2 = 840

function Home({ height, width, t }) {

    const openLayer2 = () => {
        const newWindow = window.open('https://swapalltrade.com', '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    // const { swapAllInfo, tokenInfo, latestBlogs } = useSelector(state => state.home)
    // const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();
    const history = useHistory();

    // const initApidata = () => {
    //     let lang = i18n.language === 'cn' ? 0 : 1
    //     dispatch(homeActions.getSwapAllCoinDetail());
    //     dispatch(homeActions.getSwapAllDetail());
    //     dispatch(homeActions.getLatestBlogs(lang))
    // }


    // useEffect(() => {
    //     initApidata()
    //     setLoading(false)
    //     return () => {
    //     }
    // }, [])



    // useEffect(() => {
    //     if (!loading) {
    //         // updateSummaryInfo()
    //     }
    //     return () => {
    //     }
    // }, [loading])

    // useEffect(() => {
    //     if (!loading) {
    //         initApidata()
    //     }
    // }, [i18n.language])




    const dividerLine = () => {
        return (

            <div style={{ backgroundColor: 'white', height: 1, opacity: 0.2, width: '100%', marginTop: height * 0.08, marginBottom: height * 0.08 }} />


        );
    }

    const handleOpenLink = (to) => {
        window.open(to);

    };
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: '#0A091B' }} >

            {/* header area */}
            <Grid container style={{ width: '80%', marginTop: 40 }}>
                <Grid xs={12} md={6} item style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>
                    <h4 style={{ marginTop: 0, marginBottom: 0 }} className="textfont">HPB Link AI Solutions</h4>
                    <h1 style={{ fontSize: width < 900 ? 28 : 48, color: "white" }} className="textfont">HPB Link AI Solutions</h1>
                    <h4 style={{ marginTop: 0 }} className="textfont">An enterprise-grade service for the end-to-end AI machine lifecycle</h4>
                    {/* <div style={{width:'100%', marginTop: 24 ,display:'flex'}}>
                        <div style={{ height: 52, width: 52, borderRadius: 60, backgroundColor: '#283B94',alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <img className="imageResizeToContain" style={{ height: '80%', width: '80%', }} src={buttonIcon}>
                            </img>
                        </div>
                    </div> */}
                </Grid>
                <Grid xs={12} md={6} item style={{ height: 400 }}>
                    <img className="imageResizeToContain" style={{ height: '100%', width: '100%' }} src={headImg}>

                    </img>

                </Grid>

            </Grid>



            {/* aboutUs area */}
            <div style={{ width: '80%', alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40 }}>
                {/* <img style={{ height: 60, width: 60, marginTop: 40 }} src={shapeLogo}></img> */}
                <h3 style={{ marginTop: 24 }} className="textfont">Designed for Responsible AI</h3>
                <h5 style={{ marginTop: 0, textAlign: 'center', width: width < 900 ? 400 : 600 }} className="textfont">Empower developers and data analyst with a wide range of productive experiences for building, training and deploying machine learning models faster. Accelerate time to market and foster team collaboration with industry-leading MLOps – DevOps for machine learning. Innovate on a secure and trusted platform. </h5>

            </div>


            {
                // width > 900 ?
                    <div style={{ width: '100%' }}>
                        {/* Feature 1 area */}
                        < Grid container style={{ marginTop: 120, alignItems: 'center' }}>

                            <div className='featureCol1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                <div style={{ width: 600, height: '100%', marginBottom: "12%", marginRight: "40%", display: 'flex', flexDirection: 'column' }}>
                                    <div style={{paddingLeft:24,paddingRight:12, background: "linear-gradient(#9B7AF4, #4A9DE7)" }}>
                                        <h2 style={{ fontSize: width < 900 ? 20 : 32 }} className="textfont">Unify All Your Data</h2>
                                    </div>
                                    <h4 className="textfont">Integrate, federate, and unify all disparate enterprise and external data into a logical data image.</h4>
                                </div>

                            </div>



                        </Grid>

                        {/* Feature 2 area */}
                        < Grid container style={{ marginTop: 120,  alignItems: 'center' }}>

                            <div className='featureCol2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                <div style={{ width: 600, height: '100%', marginBottom: "6%", marginLeft: "40%", display: 'flex', flexDirection: 'column' }}>
                                    <div style={{paddingLeft:24,paddingRight:12, background: "linear-gradient(#9B7AF4, #4A9DE7)" }}>
                                        <h2 style={{ fontSize: width < 900 ? 20 : 32 }} className="textfont">Rapidly Build and Train Models</h2>
                                    </div>
                                    <h4 className="textfont">Use the studio development experience to access integrated tools and best-in-class support for open-source frameworks and libraries.</h4>
                                </div>

                            </div>



                        </Grid>

                        {/* Feature 3 area */}
                        < Grid container style={{ marginTop: 120,  alignItems: 'center' }}>

                            <div className='featureCol3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                <div style={{ width: 600, height: '100%', marginBottom: "6%", marginRight: "40%", display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ paddingLeft:24,paddingRight:12, background: "linear-gradient(#9B7AF4, #4A9DE7)" }}>
                                        <h2 style={{ fontSize: width < 900 ? 20 : 32 }} className="textfont">Deliver Responsible Solutions</h2>
                                    </div>
                                    <h4 className="textfont">Understand and protect data and models, build for fairness and improve model quality.</h4>
                                </div>

                            </div>



                        </Grid>
                    </div>
                    // :
                    // <div style={{ width: '80%' }}>
                    //     {/* Feature 1 area */}
                    //     < Grid container style={{ marginTop: 40 }}>
                    //         <Grid xs={12} md={6} item style={{ height: 300 }}>
                    //             <img className="imageResizeToContain" style={{ height: '100%', width: '100%' }} src={feature1}>
                    //             </img>
                    //         </Grid>
                    //         <Grid xs={12} md={6} item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    //             <div style={{ width: "70%" }}>
                    //                 <h1 style={{ fontSize: width < 900 ? 20 : 40 }} className="textfont">{t('feature1Title')}</h1>
                    //                 <h4 className="textfont">{t('feature1Content')}</h4>
                    //             </div>
                    //         </Grid>

                    //     </Grid>

                    //     {/* Feature 2 area */}
                    //     <Grid container style={{ marginTop: 40 }}>
                    //         <Grid xs={12} item style={{ height: 300 }}>
                    //             <img className="imageResizeToContain" style={{ height: '100%', width: '100%' }} src={feature2}>
                    //             </img>
                    //         </Grid>
                    //         <Grid xs={12} item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    //             <div style={{ width: "70%" }}>
                    //                 <h1 style={{ fontSize: width < 900 ? 20 : 40 }} className="textfont">{t('feature2Title')}</h1>
                    //                 <h4 className="textfont">{t('feature2Content')}</h4>
                    //             </div>
                    //         </Grid>

                    //     </Grid>

                    //     {/* Feature 3 area */}
                    //     <Grid container style={{ marginTop: 40 }}>
                    //         <Grid xs={12} item style={{ height: 300 }}>
                    //             <img className="imageResizeToContain" style={{ height: '100%', width: '100%' }} src={feature3}>
                    //             </img>
                    //         </Grid>
                    //         <Grid xs={12} item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    //             <div style={{ width: "70%" }}>
                    //                 <h1 style={{ fontSize: width < 900 ? 20 : 40 }} className="textfont">{t('feature3Title')}</h1>
                    //                 <h4 className="textfont">{t('feature3Content')}</h4>
                    //             </div>
                    //         </Grid>

                    //     </Grid>

                    // </div>
            }
            <div style={{ height: 100 }}></div>
        </div >
    )
}




export default withTranslation()(Home);
